import React, { ChangeEvent, useEffect, useRef, useState } from 'react'
import { Container, ColorPickerButton, ColorPickerInput } from './style'

interface ColorPickerProps {
  value?: string,
  label?: string | JSX.Element,
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void,
  placeholder?: string,
  children?: React.ReactNode
}

const ColorPicker = (props: ColorPickerProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { value, onChange } = props
  const [valueColor, setValueColor] = useState(value)

  useEffect(() => {
    setValueColor(valueColor)
  }, [value])

  const handleClick = (e) => {
    e.preventDefault()
    if (inputRef.current) {
      inputRef.current.click();
      setValueColor(e.target.value)
    }
  };
  return (
    <div>
      <label>{props.label}</label>
      <Container>
        <ColorPickerButton color={value} onClick={handleClick} />
        <ColorPickerInput
          ref={inputRef}
          type="color"
          value={value}
          onChange={onChange}
        />
        {props.children}
      </Container>
    </div>
  )


}
export default ColorPicker
