import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Dialog from '~/components/Dialog'
import notify from '~/components/notify'
import { DairyApi } from '~/services'
import { useParams } from 'react-router-dom'

interface DeleteHusbandryFormProps {
  selectedHusbandry: any
  handleClose: (loadNew?: boolean) => void
}

const DeleteHusbandryForm: React.FC<DeleteHusbandryFormProps> = (props) => {
  const { selectedHusbandry, handleClose } = props
  const { t } = useTranslation()
  const { id } = useParams()

  const [isBusy, setIsBusy] = useState(false)

  async function onMilkTypeDelete() {
    setIsBusy(true)
    try {
      await DairyApi.deleteHusbandryForm(id, selectedHusbandry?.id)
      handleClose()
    } catch (error) {
      if (error.response.data) {
        notify(error.response.data.message, 'error')
      } else {
        notify(t('General error'), 'error')
      }
    }
    setIsBusy(false)
  }

  return <Dialog
    key='dialog-husbandry-delete'
    textHeader={t(`Delete form of husbandry: ${selectedHusbandry?.name}`)}
    textClose={t('Close')}
    textContent={t(`Are you sure that you want to delete ${selectedHusbandry?.name} form of husbandry?`)}
    textSubmit={t('Delete form of husbandry')}
    handleClose={() => handleClose()}
    handleSubmit={onMilkTypeDelete}
    isLoading={isBusy}
    type='abort'
    isDialogOpen={Boolean(selectedHusbandry)}
  />
}
export default DeleteHusbandryForm